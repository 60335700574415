@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Reenie+Beanie&display=swap');

@layer base {
  :root {
    --gray-10: hsl(215, 19%, 12%);
    --gray-9: hsl(227.14, 15.22%, 18.04%);
    --gray-8: hsl(210, 10.34%, 22.75%);
    --gray-7: hsl(210, 8.75%, 31.37%);
    --gray-6: hsl(210, 7.08%, 55.69%);
    --gray-5: hsl(210, 10.81%, 70.98%);
    --gray-4: hsl(212.73, 12.94%, 83.33%);
    --gray-3: hsl(210, 13.79%, 88.63%);
    --gray-2: hsl(204, 12.82%, 92.35%);
    --gray-1: hsl(210, 16.67%, 95.29%);
    /* Water color palette */

    --primary-7: hsl(340.8,14.79%,33.14%);
    --primary-6: hsl(38.4,43.86%,88.82%);
    --primary-5: hsl(35.84, 61.11%, 49.41%);
    --primary-4: hsl(2.93, 61.19%, 39.41%);
    --primary-3: hsl(140, 6.82%, 82.75%);
    --primary-2: hsl(42.86, 11.29%, 24.31%);
    --primary-1: hsl(198, 16.53%, 52.55%);
    /* Accents */
    --accent-1: hsl(200.42, 100%, 37.45%);

    /* FONTS */
    --font-1: font-family: 'Noto Sans', sans-serif;
    --font-2: font-family: 'Bebas Neue', cursive;
    --font-3: font-family: 'Beth Ellen', cursive;
  }
}

/* Headings */
@layer utilities {
  h1 {
    @apply text-[45px] sm:text-7xl xl:text-[92px] font-semibold;
    
  }
  h2 {
    @apply text-3xl font-semibold sm:text-5xl xl:text-7xl;
  }
  h3 {
    @apply text-2xl font-semibold sm:text-3xl xl:text-5xl;
  }

  h4 {
    @apply text-xl font-semibold sm:text-2xl xl:text-3xl;
  }

  h5 {
    @apply text-lg font-semibold sm:text-2xl xl:text-3xl;
  }


  /* Body Text */
  .text-large {
    @apply text-xl font-semibold xl:text-3xl sm:text-2xl;
  }

  .text-medium {
    @apply text-lg font-medium sm:text-xl xl:text-2xl;
  }

  .text-small {
    @apply text-[14px] sm:text-[19px] font-normal;
  }

  .full {
    @apply flex justify-center;
  }

  /* Centered full-width */
  .flex-center {
    @apply flex flex-wrap justify-center place-items-center lg:flex-nowrap;
  }

  .smooth {
    transition: all 0.1s ease-in-out;
  }
}
